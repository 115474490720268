import { device } from 'src/styles/breakpoints'
import { brand, grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  background-color: ${brand.sand};

  h2 {
    font-family: "Citrina", Helvetica, sans-serif;
  }

  h3 {
    font-family: "Inter", Helvetica, sans-serif;
  }
  

  padding: 40px 0;

  @media (min-width: 1024px){
    padding: 64px 0;
  }
`

export const DivPrincipal = styled.div`
  .button {
    width: 100%;
    height: 48px;
    
      @media ${device.tablet}{
        width: 552px;
      }

      @media ${device.desktopLG} {
        width: 552px;
      }
    }
`

export const OrangeLine = styled.div`
  border: 1px solid ${orange.extra};
  position: absolute;
  left: 10%;
  top: 23%;
  height: 57%;
  
  @media ${device.tablet}{
    height: 0;
    width: 73%;
    left: 14%;
    top: 16%;
  }
  
  @media ${device.desktopLG}{
    top: 19%;
  }

  @media ${device.desktopXL}{
    top: 21%;
  }
`

export const Cards = styled.article`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .cardTop{
    margin: 0;
    color: ${grayscale[500]};
    border-radius: 12px 12px 0 0;

    @media ${device.tablet} {
      height: 130px ;
    }

    @media ${device.desktopLG} {
      height: 107px;
    }

    @media ${device.desktopXL} {
      height: 100px;
    }

    @media ${device.desktopXXXL} {
      height: 100px;
    }
  }

  .cardText {
    margin: 0;
    color: ${grayscale[500]};
    border-radius: 0 0 12px 12px;

    @media ${device.tablet} {
      height: 245px;
    }

    @media ${device.desktopLG} {
      height: 177px;
    }

    @media ${device.desktopXL} {
      height: 140px;
    }

    @media ${device.desktopXXXL} {
      height: 110px;
    }

  .cardText3{
    border-radius: 0 12px 12px 12px;
  }
}
`

export const CardContainer = styled.div`
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }
`

export const Number = styled.p`
  text-align: center;
  background: ${orange.extra};
  border-radius: 12px;
  padding: 12px 20px;
  width: 54px;
  margin: 0 auto;
  position: relative;
  top: 27px;
  color: white;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  left: 1px;

  @media ${device.tablet} {
    left: auto;
  }
`
