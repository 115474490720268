import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

  img {
    position: relative;
    right: 19px;

    @media ${device.desktopLG} {
      right: 27px;
    }
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-form/image.webp');
    background-size: 457px;
    background-repeat: no-repeat;
    background-position: 89% 50%;
  }

  @media ${device.desktopXL} {
    background-size: 528px;
    background-position: 94% 90%;
  }

  @media ${device.desktopXXXL} {
    background-size: 528px;
    background-position: 98% 90%;
  }

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  background: ${orange[500]};
  padding-top: 56px;
  padding-bottom: 56px;

  button {
    height: 48px;
    text-transform: none;

    @media ${device.tablet} {
      width: 324px;
    }

    @media ${device.desktopLG} {
      width: 376px;
    }
  }
`

export const Card = styled.div`
  background: ${white};
  border-radius: 16px;
  padding: 32px 24px;

  @media ${device.tablet} {
    padding: 16px 47px;
  }

  @media ${device.desktopLG} {
    padding: 16px 27px;
  }

  label {
    font-weight: 600;
    font-family: "Inter", Helvetica, sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: ${grayscale[400]};
  }

  input {
    background: ${grayscale[100]};
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${grayscale[300]};

  @media ${device.tablet} {
    width: 352px;
  }

  @media ${device.desktopLG} {
    width: 100%;
  }
`
