import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;

  .input-error {
    label, p {
      color: #F56A50 ;
    }
    span.select{
      background-color: #FEF0ED;
    }
    input {
      background-color: #FEF0ED;
    }
    select{
      background-color: #FEF0ED !important;
    }
  }
  input{
    border-radius: 8px;
    font-family: "Inter", Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #B6B7BB;
    outline: none;
    border: none;
    padding: 15px 24px;
    font-size: 14px;
    font-weight:700;
    color: ${grayscale[500]};
    ::-webkit-input-placeholder{
      color:${grayscale[300]};
    }
    :focus{
      outline:none;
    }
  }
`
