import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  h2, h3 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  @media ${device.desktopXL} {
    padding-top: 128px;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      width: 338px; 
    }

    @media ${device.desktopXL} {
      width: 405px;
    }
  }
`

export const Card = styled.div`
  background-color: #FDF8EE;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`
